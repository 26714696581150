'use strict';

export default class Dropdown {
  constructor() {
    $('body').on('click', '.dropdown-menu[data-stop-propagation]', function (e) {
      e.stopPropagation();
      e.preventDefault();

      const $target = $(e.target);

      const $collapsableToggler = $target.data('toggle') === 'collapse' ? $target : $target.closest('[data-toggle="collapse"]');

      if ($collapsableToggler.length) {
        $($collapsableToggler.data('target') || $collapsableToggler.attr('href')).collapse('toggle')
      }
    });
  }
}
