'use strict';

import Handlebars from 'handlebars';
import get from 'lodash/get';
import set from 'lodash/set';
import uniqueId from 'lodash/uniqueId';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import upperFirst from 'lodash/upperFirst';
import groupBy from 'lodash/groupBy';
import merge from 'lodash/merge';
import md5 from 'blueimp-md5';

export default class JQueryHandlebars {
  constructor() {
    /**
     * Creates url query from object
     */
     Handlebars.registerHelper('getQuery', function(obj) {
      return (new URLSearchParams(obj)).toString();
    });

    /**
     * Concatenates string elements
     * @constructor - List of strings
     */
    Handlebars.registerHelper('concat', function() {
      let toReturn = '';

      Object.values(arguments).forEach(value => {
        if (typeof value !== 'string' && typeof value !== 'number') {
          return false;
        }

        toReturn += value.toString();
      });

      return toReturn;
    });

    Handlebars.registerHelper('thousands', function(number) {
      const parts = number.toString().split('.');

      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      return parts.join(',');
    });

    Handlebars.registerHelper('threedots', function(obj, toObj) {
      return {
        ...toObj,
        ...obj
      }
    });

    Handlebars.registerHelper('groupbysections', function(sections, items, orderBy) {
      const groupedItems = groupBy(items, 'section');

      const toReturn = sections.map(section => {
        const tmpItems = groupedItems[section.id] || []

        if (typeof orderBy === 'string') {
          tmpItems.sort((a, b) => {
            const orderByA = a[orderBy];

            const orderByB = b[orderBy];

            if (orderByA > orderByB) {
              return -1;
            }

            if (orderByA < orderByB) {
              return 1;
            }

            return 0;
          });
        }

        return {
          ...section,
          items: groupedItems[section.id] || []
        }
      });

      return toReturn
    });

    Handlebars.registerHelper('periodicalparser', function(notParsedContents, locale) {
      const toReturn = [];

      const mainId = uniqueId('generated_id_');

      const getIssueOrder = issue => {
        const year = issue.year[0];

        if (!isNaN(issue.issue[0])) {
          return parseInt(issue.issue[0]);
        }

        const pattern = new RegExp('^(A\. [0-9]+, ){0,1}(([a-z]{3})\., (([0-9]+), ){0,1}){0,1}(fasc\. )(.*?)*$');

        const months = [
          'gen',
          'feb',
          'mar',
          'apr',
          'mag',
          'giu',
          'lug',
          'ago',
          'set',
          'ott',
          'nov',
          'dic'
        ];

        if (!groups) {
          return null;
        }

        const groups = pattern.exec(issue.issue[0]);

        let month = months.indexOf(groups[3]);

        if (month < 10) {
          month = '0' + month;
        }

        let fasc = groups[5] || groups[7].split('/')[0];

        if (fasc.length < 6) {
          fasc = new Array(7 - fasc.length).join('0') + fasc;
        }

        return parseInt(year + month + fasc);
      };

      const sorter = (b, a) => {
        if (a.order > b.order) {
          return -1;
        }

        if (a.order < b.order) {
          return 1;
        }

        return 0;
      };

      notParsedContents.documents.forEach(document => {
        document.issues.forEach(issue => {
          const path = []

          let tmpYearItemIndex = toReturn.findIndex(item => {
            return item.label === (issue.yearLabel || issue.year[0]);
          });

          if (tmpYearItemIndex < 0) {
            tmpYearItemIndex = toReturn.length;

            toReturn.push({
              order: issue.yearOrder || parseInt(issue.year[0]),
              label: issue.yearLabel || issue.year[0],
              contents: []
            });
          }

          path.push(tmpYearItemIndex, 'contents');

          if (issue.issueMonthLabel) {
            let tmpMonthItemIndex = toReturn[tmpYearItemIndex].contents.findIndex(item => {
              return item.issueMonthLabel === issue.issueMonthLabel;
            });

            moment.locale(typeof locale === 'string' ? locale : 'it');

            if (tmpMonthItemIndex < 0) {
              tmpMonthItemIndex = toReturn[tmpYearItemIndex].contents.length;

              toReturn[tmpYearItemIndex].contents.push({
                issueMonthLabel: issue.issueMonthLabel,
                label: upperFirst(moment(issue.issueMonthOrder / 100, 'M').format('MMMM')),
                order: issue.issueMonthOrder,
                contents: []
              });
            }

            path.push(tmpMonthItemIndex, 'contents');
          }

          const tmpItem = get(toReturn, path);

          tmpItem.push({
            order: issue.issueOrder || getIssueOrder(issue) || issue.issueLabel,
            label: issue.issueLabel || issue.issue[0],
            manifests: [issue.manifestUrl],
            issue
          });

          set(toReturn, path, tmpItem);
        });
      });

      toReturn.sort(sorter);

      return {
        __id: mainId,
        contents: toReturn.map(item => {
          item.contents.sort(sorter);

          return {
            ...item,
            parentId: mainId,
            contents: item.contents.map(innerItem => {
              if (innerItem.contents) {
                innerItem.contents.sort(sorter);
              }

              return {
                ...innerItem,
                parentId: mainId,
              };
            })
          }
        })
      };
    });

    Handlebars.registerHelper('md5', function(value) {
      return md5(value);
    });

    Handlebars.registerHelper('arrayparams', function(paramName, paramsArray) {
      const toReturn = [];

      paramsArray.forEach(param => {
        toReturn.push(paramName + '=' + encodeURIComponent(JSON.stringify(param)));
      });

      return toReturn.join('&');
    });

    Handlebars.registerHelper('filter', function(items, prop, value, operator) {
      return items.filter(item => {
        switch (operator) {
          case 'neq':
            return item[prop] !== value;
          default:
            return item[prop] === value;
        }
      });
    });

    Handlebars.registerHelper('jquery', function() {
      const path = arguments[0];

      const params = [];

      for (let i = 1; i < arguments.length - 1; i++) {
        params.push(arguments[i]);
      }

      const jqueryMethod = get($, path);

      if (jqueryMethod) {
        return jqueryMethod(...params);
      }

      return console.log('jQueryPath "', path, '" not found');
    });

    Handlebars.registerHelper('isset', function(value) {
      return typeof value !== 'undefined';
    });

    Handlebars.registerHelper('every', function(index, amount) {
      return index % amount;
    });

    Handlebars.registerHelper('highlight', function(thisSentence, inThisString) {
      const words = (thisSentence || '').split(' ');

      words.filter(word => {
        return !(!word);
      }).forEach(word => {
        const re = new RegExp(word, 'ig');

        inThisString = inThisString.replace(re, '<strong>' + word + '</strong>')
      });

      return inThisString;
    });

    Handlebars.registerHelper('replace', function(source, pattern, withValue) {
      const regExp = new RegExp(pattern, 'ig');

      return source.replace(regExp, withValue);
    });

    Handlebars.registerHelper('inputvalue', function(selector) {
      selector = $('<textarea>').html(selector).text();

      return $(selector).val();
    });

    Handlebars.registerHelper('arrayitems', function(selector, dataAttribute) {
      const toReturn = [];

      $(selector).each(function() {
        toReturn.push($(this).data(dataAttribute));
      });

      return JSON.stringify(toReturn);
    });

    Handlebars.registerHelper('get', function(fromObj, path) {
      return get(fromObj, path);
    });

    Handlebars.registerHelper('prefixer', function(prefixName, value) {
      if (value.startsWith('"')) {
        return prefixName + '[' + value + ']';
      }

      return prefixName + '.' + value;
    });

    Handlebars.registerHelper('set', function(toAdd, path, inObj, toReturn) {
      set(inObj, path, toAdd);

      if (toReturn) {
        return inObj;
      }
    });

    Handlebars.registerHelper('not', function(a) {
      return !a;
    });

    Handlebars.registerHelper('or', function(a, b) {
      return !(!a) || !(!b);
    });

    Handlebars.registerHelper('and', function(a, b) {
      return !(!a) && !(!b);
    });

    Handlebars.registerHelper('gt', function(a, b) {
      return a > b;
    });

    Handlebars.registerHelper('lt', function(a, b) {
      return a < b;
    });

    Handlebars.registerHelper('gte', function(a, b) {
      return a >= b;
    });

    Handlebars.registerHelper('lte', function(a, b) {
      return a <= b;
    });

    /**
     * Concatenates string elements
     * @constructor - List of strings
     */
    Handlebars.registerHelper('pagination', function(pagination, options) {
      const { max, total, current } = pagination;

      const first = current >= max;

      let from = !first ? 1 : current - (parseInt(max / 2) - 1);

      let to = from + (max - 1);

      if (to > total) {
        to = total;
      }

      if (from > to - max) {
        from = to - max;
      }

      if (from < 1) {
        from = 1;
      }

      const last = to < total;

      const pages = []

      for (let i = from; i <= to; i++) {
        pages.push(i);
      }

      return options.fn({
        first,
        last,
        pages,
        total,
        current
      });
    });

    Handlebars.registerHelper('len', function(item) {
      return item.length;
    });

    Handlebars.registerHelper('bodytomapconfig', function(results) {
      return JSON.stringify({
        markers: results.body.map(item => {
          return {
            coords: item.coords,
            info: item.cols[0].value
          }
        })
      })
    });

    Handlebars.registerHelper('getsortclass', function(sortParam, obj) {
      const { sort } = obj.pagination;

      if (sort.value === sortParam) {
        return 'sort sort-' + (!sort.type ? 'asc' : 'desc');
      }

      return '';
    });

    Handlebars.registerHelper('eq', function(a, b) {
      return a === b;
    });

    /**
     * JSON parser
     * @constructor - JSON parser
     */
    Handlebars.registerHelper('json', function(what, item) {
      switch (what) {
        case 'parse':
          try {
            return JSON.parse(item);
          } catch (error) {
            return {};
          }
        default:
          try {
            return JSON.stringify(item);
          } catch (error) {
            return '{}';
          }
      }
    });

    Handlebars.registerHelper('math', function(type, first, second) {
      const mathTypes = {
        add: (first, second) => {
          return first + second;
        },
        subtract: (first, second) => {
          return first - second;
        }
      };

      if (!mathTypes[type]) {
        return console.log('l\'helper math', type, 'non esiste');
      }

      return mathTypes[type](first, second);
    });

    Handlebars.registerHelper('merge', function(a, b) {
      const toReturn = {};

      merge(toReturn, a, b);

      return toReturn;
    });

    Handlebars.registerHelper('delete', function(prop, fromObj) {
      delete fromObj[prop];

      return fromObj;
    });

    Handlebars.registerHelper('is', function(item, type) {
      return typeof item === type;
    });

    Handlebars.registerHelper('timestap', function() {
      const date = new Date();

      return date.getTime();
    });

    Handlebars.registerHelper('mapitem', function(toGet, items) {
      return items.map(item => {
        return item[toGet];
      });
    });

    Handlebars.registerHelper('inarray', function(toFind, searchIn) {
      return searchIn.indexOf(toFind) >= 0;
    });

    Handlebars.registerHelper('urlencode', function (url) {
      return encodeURIComponent(url);
    });

    Handlebars.registerHelper('clearvalues', function(obj) {
      const clearItem = field => {
        if (field.fields) {
          return clearItem(field)
        }

        if (field.options) {
          field.options = field.options.map(option => {
            delete option.selected;

            delete option.checked;

            return option;
          })
        }

        delete field.value;

        delete field.isFirst;

        return field;
      }

      delete obj.isFirst;

      obj.fields = obj.fields.map(field => {
        return clearItem(field)
      });

      return obj;
    });

    Handlebars.registerHelper('orderby', function(field, unorderedArray, isObj) {
      if (isObj) {
        return Object.entries(unorderedArray).map(([key, value]) => {
          return {
            key,
            value
          }
        }).sort((a, b) => {
          const fieldA = a.value[field];

          const fieldB = b.value[field];

          if (fieldA > fieldB) {
            return 1;
          }

          if (fieldB < fieldA) {
            return -1;
          }

          return 0;
        });
      }

      unorderedArray.sort((a, b) => {
        const fieldA = a[field];

        const fieldB = b[field];

        if (fieldA > fieldB) {
          return 1;
        }

        if (fieldB < fieldA) {
          return -1;
        }

        return 0;
      });

      return unorderedArray;
    });

    Handlebars.registerHelper('getfromjsvariable', function(variable, path) {
      return typeof path !== 'string' ? eval(variable) : get(eval(variable), path);
    });

    /**
     * Include an Handlebars template
     * @constructor
     * @param {string} element - The attribute to look for in the DOM
     * @param {object} obj - The object to pas at the template
     * @return {string} - The generated html
     */
    Handlebars.registerHelper('include', function(element, obj) {
      if ($(element).length === 0) {
        return;
      }

      const source = $(element).html();

      const template = Handlebars.compile(source);

      const html = template(obj);

      return html;
    });

    $.extend({
      handlebars: (config) => {
        $(document).trigger('handlebars.render.start');

        const { template, container, context, append = null } = config;

        const addIds = obj => {
          if (isArray(obj)) {
            return obj.map(item => {
              if (isObject(item)) {
                return addIds(item);
              }

              return item
            });
          }

          if (isObject(obj)) {
            if (!obj.__id) {
              obj.__id = uniqueId('generated_id_');
            };

            Object.entries(obj).forEach(([key, value]) => {
              if (isObject(value)) {
                obj[key] = addIds(value);
              }
            });
          }

          return obj;
        };

        if (!context.__id) {
          addIds(context);
        }

        const source = $(template).html();

        const compiledTemplate = Handlebars.compile(source);

        const html = compiledTemplate(context);

        if (config.returnHtml) {
          return html;
        }

        switch (append) {
          case 'insertBefore':
          case 'insertAfter':
            $(html)[append]($(container));

            break;
          default:
            $(container)[append || 'html'](html);

            break;
        }

        $(document).trigger('handlebars.render.end', [$(container)]);
      }
    });

    $('body').on('click', '[data-replace-block-with]', function(e) {
      e.preventDefault();

      const { block, replacer } = $(this).data('replace-block-with');

      $.handlebars({
        template: replacer.template,
        container: block,
        context: replacer.context,
        append: 'replaceWith'
      })
    });
  }
}
