'use strict';

export default class Chart {
  constructor() {
    (function($) {
      $.extend({
        chart: {
          init: $obj => {
            const chartConfig = $obj.data('chart');

            const data = chartConfig.data.map((item, index) => {
              if (item[2]) {
                return {
                  x: parseInt(item[0]),
                  y: parseInt(item[1]),
                  pointWidth: 10,
                  events: {
                    click: () => {
                      $('#' + [
                        chartConfig.id,
                        index
                      ].join('-')).trigger('click');
                    }
                  }
                };
              }

              return item;
            });

            data.unshift([
              data[1] ? parseInt(data[0].x - ((data[1].x - data[0].x) / 2)) : data[0].x - 1,
              0
            ]);

            data.push([
              data[1] ? parseInt(data[data.length - 1].x + ((data[data.length - 1].x - data[data.length - 2].x) / 2)) : data[0].x + 1,
              0
            ]);

            Highcharts.chart($obj.get(0), {
              credits: {
                enabled: false,
              },
              chart: {
                type: 'column',
                animation: false,
              },
              plotOptions: {
                column: {
                  borderWidth: 0,
                }
              },
              title: {
                text: chartConfig.title || ''
              },
              xAxis: {
                type: 'category',
                labels: {
                  rotation: -45,
                  style: {
                    fontSize: '6px',
                    fontFamily: 'Verdana, sans-serif'
                  }
                }
              },
              yAxis: {
                min: 0,
                max: null,
                title: {
                  enabled: false
                },
                labels: {
                  style: {
                    fontSize: '6px',
                    fontFamily: 'Verdana, sans-serif'
                  }
                }
              },
              legend: {
                verticalAlign: 'top',
                itemStyle: {
                  fontSize: '6px',
                  fontFamily: 'Verdana, sans-serif'
                }
              },
              responsive: {
                rules: [
                  {
                    chartOptions: {
                      yAxis: {
                        labels: {
                          style: {
                            fontSize: '8px',
                          }
                        }
                      },
                      xAxis: {
                        labels: {
                          style: {
                            fontSize: '8px',
                          }
                        }
                      },
                      legend: {
                        itemStyle: {
                          fontSize: '8px'
                        }
                      }
                    },
                    condition: {
                      minWidth: 195
                    }
                  },
                  {
                    chartOptions: {
                      yAxis: {
                        labels: {
                          style: {
                            fontSize: '10px',
                          }
                        }
                      },
                      xAxis: {
                        labels: {
                          style: {
                            fontSize: '10px',
                          }
                        }
                      },
                      legend: {
                        itemStyle: {
                          fontSize: '10px'
                        }
                      }
                    },
                    condition: {
                      minWidth: 300
                    }
                  }
                ]
              },
              series: [{
                animation: false,
                name: chartConfig.legend,
                data
              }]
            });
          }
        }
      });
    })($);

    const initChart = () => {
      $('[data-chart]:not([data-has-chart])').each(function() {
        $(this).data('has-chart', true);

        $(this).attr('data-has-chart', true);

        $.chart.init($(this));
      });
    };

    initChart();

    $(document).on('handlebars.render.end', function() {
      initChart();
    });
  }
}
