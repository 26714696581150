'use strict';

export default class Modal {
  constructor() {
    (function($) {
      $.extend({
        confirm: {
          init: $obj => {
            const confirmConfig = $obj.data('confirm');

            $.handlebars({
              template: '#confirm-modal-template',
              context: confirmConfig,
              append: 'html',
              container: '#confirm-modal'
            });

            $('#confirm-modal').modal('toggle');
          },
          destroy: $obj => {}
        }
      });
    })($);

    $('body').on('click', '[data-confirm]', function(e) {
      e.preventDefault();

      $.confirm.init($(this));
    });

    $('body').on('click', '[data-toggle="dynamic-modal"]', function(e) {
      e.preventDefault();

      const $dynamicModal = $('#dynamic-modal');

      if ($dynamicModal.length) {
        const dynamicModalConfig = $(this).data('modal-config');

        $.handlebars({
          template: '#dynamic-modal-template',
          context: dynamicModalConfig,
          append: 'html',
          container: $dynamicModal
        });

        $dynamicModal.modal('toggle');
      }
    });

    const writeCookie = (key, value) => {
      let date = new Date();

      date.setTime(+ date + (15 * 86400000)); // il cookie dura 15 giorni

      document.cookie = key + "=" + value + "; expires=" + date.toGMTString() + "; path=/";

      return value;
    };

    if ( !document.cookie.includes('alphy') ){
      writeCookie('alphy', 'true');

      $('#aiphy-modal').addClass('show');

      $('body').addClass('with-alphy');
    }

    $('body').on('click', '[alphy-modal-close]', function() {
      $('#aiphy-modal').removeClass('show');

      $('body').removeClass('with-alphy');
    });

    $('body').on('click', function(e) {
      const alphyOpen = $('body').hasClass('with-alphy');

      const $target = $(e.target);

      if (alphyOpen && !$target.hasClass('modal-2') && !$target.closest('.modal-2').length && !$target.hasClass('cc_div') && !$target.closest('.cc_div').length) {
        $('[alphy-modal-close]').trigger('click');
      }
    });
  }
}
