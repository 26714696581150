'use strict';

import { ajax } from '../../_scripts/commons/ajax'
import Snackbar from 'node-snackbar';

export default class From {
  constructor() {
    $('body').on('submit', 'form:not([data-ajax-form]):not([data-submitted]):not([data-fm-namespace])', function (e) {
      e.preventDefault();

      $(this).data('submitted', true);

      $(this).attr('data-submitted', true);

      $(this).find('[name]').each(function () {
        if (!$(this).val()) {
          $(this).attr('name', null);
        }
      });

      $(this).submit();
    });

    $('body').on('reset', 'form', function () {
      setTimeout(() => {
        $(this).find('select').trigger('change');
      }, 10);
    });

    $('body').on('submit', '[data-ajax-form]', function (e) {
      e.preventDefault();

      ajax.run({
        url: $(this).attr('action'),
        type: $(this).attr('method') || 'get',
        data: $(this).closest('form').serialize()
      }).then(json => {
        Snackbar.show({
          text: json.message,
          pos: 'bottom-center',
          showAction: false
        });

        const toggle = $(this).data('toggle');
        if (toggle) {
          try {
            $.toggler[toggle.callback]($(this), toggle.params)
          }
          catch (e) {
            console.error(e)
          }
        }
      });

      $(this).closest('.modal').modal('toggle');
    });

    const addTag = $obj => {
      const tag = document.createElement('li');

      const tagContent = $obj.val().trim();

      if (tagContent !== '') {
        tag.innerHTML += '<div class="text">' + tagContent + '</div><button class="delete-button">✕</button>';

        $obj.parent().find('.tags').append($(tag));

        $obj.val('');

        $obj.closest('form').trigger('submit');
      }
    }

    $('body').on('keydown', '.tags-input input', function (event) {
      if (event.originalEvent.code === 'Enter') {
        event.preventDefault();

        addTag($(this));
      }
    });

    $('body').on('click', '.tags-input [type="submit"]', function (event) {
      event.preventDefault();

      const $input = $(this).parent().siblings('input');

      addTag($input);
    });

    $('body').on('click', '.tags .delete-button', function () {
      const $form = $(this).closest('form');

      $(this).parent().remove();

      $form.trigger('submit');
    });
  }
}
