'use strict';

const uniqueId = require('lodash/uniqueId')

export default class NavigationCache {
  constructor() {
    $.extend({
      navigationCache: {
        init: ($obj, preventReplace) => {
          let $navigationCache = $obj.closest('[data-navigation-cache]');

          if (!$navigationCache.length) {
            $navigationCache = $('[data-navigation-cache]');
          }

          const html = $navigationCache.html();

          if (!preventReplace) {
            window.history.replaceState({
              html
            }, '');
          }

          const navigationId = uniqueId('navigation-');

          window.history.pushState({}, preventReplace ? html : '', '#' + navigationId);
        },
        replace: () => {
          const $navigationCache = $('[data-navigation-cache]');

          const html = $navigationCache.html();

          if (lastContentRenderedTimeout) {
            clearTimeout(lastContentRenderedTimeout);

            lastContentRenderedTimeout = null;
          }

          lastContentRenderedTimeout = setTimeout(() => {
            window.history.replaceState({
              html
            }, '')
          }, 300);
        }
      }
    });

    $('body').on('click', '[data-toggle-infinite-scroll]:not([data-prevent-navigation-cache]), [data-toggle-infinite-scroll-params]:not([data-prevent-navigation-cache])', function(e) {
      e.preventDefault();

      $.navigationCache.init($(this));
    });

    let lastContentRenderedTimeout = null

    $(document).on('handlebars.render.end cards.group.end', function() {
      $.navigationCache.replace();
    });

    $(window).on('popstate', function() {
      const $navigationCache = $('[data-navigation-cache]');

      const $html = $((window.history.state || {}).html || $navigationCache.html());

      $html.find('[data-loading="true"]').data('loading', false).attr('data-loading', false);

      const emptyResults = !$html.find('[data-infinite-scroll-results-container]').children().length;

      $navigationCache.html($html);

      if (emptyResults) {
        $('[data-infinite-scroll-toggle-card].d-none').removeClass('d-none');
      }

      // Scroll to focus if in container
      $('.card.active').each(function () {
        const $container = $(this).closest('[data-has-scroll-event]');

        if ($container.length) {
          $container.scrollTop($(this).position().top - (($container.height() / 2) - $(this).height()));
        }
      });

      $html.find('[data-has-scroll-event]').each(function() {
        $(this).removeAttr('data-has-scroll-event');
      });

      $.infiniteScroll.scrollEvent();
    });
  }
};
