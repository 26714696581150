'use strict';

import { ajax } from '../../_scripts/commons/ajax';
import debounce from 'lodash/debounce';
import _ from 'lodash';

export default class SearchBlock {
  constructor() {
    (function ($) {
      $.extend({
        searchBlock: {
          init: $obj => {
            $obj.data('renderized', true);

            $obj.attr('data-renderized', true);

            const { context, container, template } = $obj.data('form-config');

            $(document).trigger('sb:init:start');

            $.handlebars({
              context,
              container,
              template
            });

            $(document).trigger('sb:init:end');
          },
          preview: $form => {
            const items = []

            const getValue = $obj => {
              if ($obj.is('input')) {
                if ($obj.is(':radio') || $obj.is(':checkbox')) {
                  return $obj.is(':checked') ? true : '';
                }

                return $obj.val();
              }

              if ($obj.is('select')) {
                const options = [];

                $obj.find('option:selected').each(function () {
                  options.push($(this).text());
                });

                return options.join(' OR ');
              }
            };

            const getPreviewLabel = $obj => {
              const tmpPreviewLabel = $obj.data('preview-label');

              const $input = $obj.find('[type="radio"], [type="checkbox"]');

              const isCheckboxOrRadio = !(!$input.length);

              const isChecked = isCheckboxOrRadio ? !(!$input.is(':checked')) : true;

              if (!tmpPreviewLabel || !isChecked) {
                return null
              }

              return tmpPreviewLabel;
            };

            $form.find('[data-group-label]').each(function () {
              const subItems = [];

              $(this).find('[data-preview-group]').each(function () {
                let previewGroupTemplate = $(this).data('preview-group');

                const groupWords = [];

                const groupItems = [];

                $(this).find('[data-type]:not([data-type="label"])').each(function () {
                  groupItems.push(getPreviewLabel($(this)) || getValue($(this).find('[name]')));
                });

                previewGroupTemplate.match(/(\[[0-9]+\])/ig).forEach(item => {
                  const index = parseInt(item.replace(/(\[|\])/ig, ''));

                  groupWords.push(groupItems[index]);
                });

                if (!groupWords.filter(item => !item).length) {
                  groupItems.forEach((groupWord, index) => {
                    previewGroupTemplate = previewGroupTemplate.replace('[' + index + ']', groupWord);
                  });

                  subItems.push(previewGroupTemplate);
                }
              });

              const singlePreviews = []

              $(this).find('[data-preview]').each(function () {
                const value = getPreviewLabel($(this)) || getValue($(this).find('[name]'));

                if (value || value === 0) {
                  singlePreviews.push($(this).data('preview') + (typeof value === 'boolean' ? '' : value));
                }
              });

              if (singlePreviews.length) {
                subItems.push(singlePreviews.join(' AND '));
              }

              items.push({
                label: $(this).data('group-label'),
                items: subItems.map((item, index) => {
                  const lastRE = new RegExp('__last(.*?)tsal__', 'ig');

                  return item.replace(lastRE, index === subItems.length - 1 ? '' : '$1');
                })
              });
            });

            $('[data-info-block]').each(function () {
              const infoBlockConfig = $(this).data('info-block');

              $.handlebars({
                context: items.filter(item => {
                  return !(!item.items.length)
                }),
                container: $(this),
                template: infoBlockConfig.template
              });
            });
          },
          hideIf: {
            init: () => {
              $('[data-hide-if]:not([data-hide-if="false"])').each(function () {
                const hideIfConfig = $(this).data('hide-if');

                const $obj = $(this);

                Object.entries(hideIfConfig).forEach(([key, value]) => {
                  if ($.searchBlock.hideIf[key]) {
                    try {
                      $.searchBlock.hideIf[key]($obj, value);
                    } catch (err) {
                      console.log(err);
                    }
                  }
                });
              });
            },
            noOptions: ($obj, hideIfSelector) => {
              const $selector = $(hideIfSelector);

              if ($selector.find('option:not([data-empty])').length) {
                return $obj.removeClass('d-none');
              }

              return $obj.addClass('d-none');
            },
            notHasValue: ($obj, hideIfSelector) => {
              const $selector = $(hideIfSelector.field);

              if ($selector.val() === hideIfSelector.value) {
                return $obj.removeClass('d-none');
              }

              return $obj.addClass('d-none');
            }
          },
          fillRelated: $obj => {
            const { field, paramName, type = 'post', url, optionsTemplate } = $obj.data('fill-related');

            const data = {};

            let value = $obj.val() || '*';

            data[paramName || $obj.attr('name')] = value;

            $.ajax({
              type,
              dataType: 'json',
              url,
              data,
              success: (json) => {
                $.handlebars({
                  context: {
                    options: json.data,
                    placeholder: $(field).data('placeholder')
                  },
                  container: field,
                  template: optionsTemplate
                });

                $(field).trigger('change');
              }
            });
          },
          changeNameCount: (fields, count) => {
            fields.forEach(field => {
              if (field.fields) {
                $.searchBlock.changeNameCount(field.fields, count);
              } else if (field.name) {
                field.name = field.name.replace(/\[[0-9]\]/is, '[' + count + ']');
              }
            })
          },
          duplicateRow: $obj => {
            const { template, context } = $obj.data('duplicate');

            const container = $obj.closest('[data-type="row"]');

            $.searchBlock.changeNameCount(context.fields, container.siblings().length + (context.startFrom || 0) + 1);

            $obj.closest('[data-type="row"]').removeClass('last-item');

            $obj.closest('.duplicate-row').remove();

            $.handlebars({
              context,
              container,
              append: 'insertAfter',
              template
            });
          }
        }
      })
    })($);

    $(document).on('sb:init:end', function () {
      $.searchBlock.hideIf.init();

      $.searchBlock.preview($('form'));
    });

    $('body').on('change', 'select', function () {
      $.searchBlock.hideIf.init();
    });

    $('body').on('click', '[data-duplicate]', function (e) {
      e.preventDefault();

      $.searchBlock.duplicateRow($(this));
    });

    $('body').on('click', '[data-list-changer] [data-list-changer-value]', function (e) {
      e.preventDefault();

      const listChangerConfig = $(this).closest('[data-list-changer]').data('list-changer');

      const value = $(this).data('list-changer-value');

      $('[name="' + listChangerConfig.input.name + '"]').val(value);

      $('[name="' + listChangerConfig.select.name + '"]').val(listChangerConfig.select.value);

      $('[name="' + listChangerConfig.select.name + '"]').change();

      $(this).closest('.modal').modal('toggle');
    });

    $('body').on('change', '[name]', debounce(function () {
      const $form = $(this).closest('form');

      if ($form.length) {
        $.searchBlock.preview($form);
      }
    }, 500));

    $('body').on('change', '[data-fill-related]:not([data-fill-related="false"]):not([data-fill-related=""])', function () {
      $.searchBlock.fillRelated($(this));
    });

    $('body').on('change', '[data-coords] input', function () {
      $(this).closest('[data-coords]').find('input').each(function () {
        if (!$(this).val()) {
          $(this).val('0');
        }
      });
    });

    const initForm = () => {
      $('[data-form-config]:not([data-renderized])').each(function () {
        $.searchBlock.init($(this));
      });
    };

    initForm();

    $(document).on('handlebars.render.end', initForm);

    const runInnerSelect2 = $obj => {
      const defaultConfig = {
        width: 'resolve',
        containerCssClass: 'form-control'
      };

      const currentConfig = $obj.data('select-2') || {};

      if (currentConfig.url) {
        currentConfig.ajax = {
          url: currentConfig.url,
          processResults: (json, params) => {
            if ($obj.attr('enter-to-submit')) {
              json.data.unshift({
                value: params.term,
                label: params.term,
                name: $obj.attr('name')
              })
            }

            return {
              results: json.data.map(item => {
                if (item.children) {
                  return {
                    text: item.label,
                    children: item.children.map(innerItem => {
                      return {
                        id: innerItem.value,
                        text: innerItem.label,
                        name: innerItem.name
                      }
                    })
                  };
                }

                return {
                  id: item.value,
                  text: item.label,
                  name: item.name
                };
              })
            };
          }
        };
      }

      $obj.data('has-select2', true);

      $obj.attr('data-has-select2', 'true');

      $obj.select2({
        ...defaultConfig,
        ...currentConfig
      });
    };

    const runSelect2 = () => {
      $('[data-select-2]:not([data-has-select2])').each(function () {
        const $obj = $(this);

        const options = $obj.data('options') || null;

        const currentConfig = $obj.data('select-2') || {};

        if (options) {
          return ajax.run({
            url: options.url,
          }).then(json => {
            $.handlebars({
              context: {
                allowClear: currentConfig.allowClear,
                options: json.data,
              },
              container: $obj,
              template: options.template
            });

            runInnerSelect2($obj);
          })
        }

        runInnerSelect2($obj);
      });
    };

    $('body').on('keyup blur focus', '.select2-search__field', _.throttle(function () {
      const $select2Item = $('[aria-owns="' + $(this).attr('aria-controls') + '"]');

      const isEmpty = $(this).val() === '';

      $select2Item[(isEmpty ? 'remove' : 'add') + 'Class']('select2-selection---not-empty');
    }, 100));

    $('body').on('click', '[data-toggle="row"]', function (e) {
      e.preventDefault();

      $(this).closest('[data-type="fixed-info-row"]').toggleClass('active');
    });

    const initEnableIfActive = () => {
      $('[data-enable-if-not-empty]:not(data-has-enable-if-not-empty)').each(function () {
        const $obj = $(this);

        $obj.data('has-enable-if-not-empty', true);

        $obj.data('data-has-enable-if-not-empty', true);

        $obj.data('enable-if-not-empty').forEach(item => {
          $(item).on('keyup keydown change', function (e) {
            $obj[($(this).val() ? 'add' : 'remove') + 'Class']('active');

            if (!$(this).val() && e.originalEvent.code === 'Enter') {
              e.preventDefault();
            }
          });
        });
      });
    };

    initEnableIfActive();

    $(document).on('handlebars.render.end', function () {
      runSelect2();

      initEnableIfActive();

      $('[data-fill-related]:not([data-has-fill-related]):not([data-fill-related="false"]):not([data-fill-related=""])').each(function () {
        $(this).data('has-fill-related', true);

        $(this).attr('data-has-fill-related', true);

        if ($(this).val()) {
          $.searchBlock.fillRelated($(this));
        }
      });
    });

    $('body').on('reset', 'form', function () {
      $(this).find('[name]').first().change();
    });

    $('body').on('submit', 'form[data-inner-search-form]', function () {
      let $input = $(this).find('[type="text"]');

      $(this).find('[data-clear-search="clear"]')[($input.val() ? 'remove' : 'add') + 'Class']('d-none');

      $(this).find('[data-clear-search="search"]')[($input.val() ? 'add' : 'remove') + 'Class']('d-none');
    });

    $('body').on('click', '[data-clear-search="clear"]', function (e) {
      e.preventDefault();

      let $form = $(this).closest('form');

      let $input = $form.find('[type="text"]');

      $input.val('');

      $input.trigger('change');

      $form.trigger('submit');
    });

    $('body').on('change', 'select[enter-to-submit]', function () {
      const $form = $(this).closest('form');

      const name = $(this).select2('data')[0].name;

      if (name) $(this).attr('name', name);

      $form.submit();
    });

    $(document).on('keyup', '.select2-search__field', function (e) {
      let $select = $('.select2.select2-container--open').siblings('select');

      if (!($select.attr('enter-to-submit'))) return;

      $select.attr('value', $(this).val());
    });

    $('body').on('click', '.select2 .selection', function (e) {
      let $select = $(this).parent().siblings('select');

      if (!$select || !($select.attr('enter-to-submit'))) return;

      $select.val($select.attr('value'));
    });

    $('body').on('click', '.input-group-append button', function (e) {
      let $select = $(this).parent().siblings('select');

      if (!$select || !($select.attr('enter-to-submit'))) return;

      e.preventDefault();

      var newOption = new Option('', $select.attr('value'), true, true);

      $select.append(newOption).trigger('change');
    });

    $('body').on('click', function (e) {
      const $target = $(e.target);

      const isToggler = $target.data('toggle') === 'row';

      const isBlock = $target.hasClass('search-block-fixed-info-row');

      const hasParent = !(!$target.closest('.search-block-fixed-info-row').length);

      if (!isToggler && !isBlock && !hasParent) {
        $('[data-type="fixed-info-row"]').removeClass('active');
      }
    });

    runSelect2();
  }
}
