'use strict';

import _ from 'lodash';

export default class Header {
  constructor() {
    const changeMenuType = function() {
      const scrollPosition = $(window).scrollTop();

      const smallClass = 'small-menu'

      if (scrollPosition > 200) {
        return $('body').addClass(smallClass);
      }

      return $('body').removeClass(smallClass);
    };

    changeMenuType();

    $(window).on('scroll', _.throttle(changeMenuType, 100));

    $('#main-menu').on('show.bs.collapse hide.bs.collapse', function(e) {
      $('html')[(e.type === 'show' ? 'add' : 'remove') + 'Class']('with-modal');
    });
  }
}
