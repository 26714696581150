'use strict';

const throttle = require('lodash/throttle');

export default class VerticalNavigation {
  constructor() {
    const activeContainer = (isActive) => {
      $('[data-vertical-navigation-container]')[(isActive ? 'add' : 'remove') + 'Class']('active');
    };

    const activeItem = () => {
      const centerOfPage = $(window).scrollTop() + ($(window).height() / 2);

      const $items = $('[data-vertical-navigation-link]');

      $items.removeClass('active');

      let isActive = false;

      $items.each(function() {
        const $target = $($(this).attr('href'));

        const targetTop = $target.offset().top;

        const targetButton = targetTop + $target.height();

        if (targetTop < centerOfPage && targetButton > centerOfPage) {
          $(this).addClass('active');

          isActive = true
        }
      });

      activeContainer(isActive);
    };

    const initVerticalNavigation = () => {
      $('[data-vertical-navigation-container]').each(function() {
        const verticalNavigation = $(this).data('vertical-navigation-container');

        const items = [];

        $('[data-vertical-navigation-item]').each(function() {
          items.push($(this).data('vertical-navigation-item'));
        });

        $.handlebars({
          template: verticalNavigation.template,
          context: items,
          container: this
        });

        activeItem();
      });
    };

    initVerticalNavigation();

    $(document).on('handlebars.render.end', initVerticalNavigation);

    $(window).on('scroll', throttle(() => {
      activeItem();
    }, 300));

    $('body').on('click', '[data-vertical-navigation-container] [data-vertical-navigation-link]', function(e) {
      e.preventDefault();

      const $target = $($(this).attr('href'));

      $('body, html').animate({
        scrollTop: $target.offset().top - $('#main-menu').height()
      }, 300);
    });
  }
};
