'use strict';

import ClipboardJS from 'clipboard';

export default class Clipboard {
  constructor() {
    const initClipboard = () => {
      $('[data-clipboard]:not([data-has-clipboard])').each(function() {
        $(this).data('has-clipboard', true);

        $(this).attr('data-has-clipboard', true);

        const clipboard = new ClipboardJS(this);

        clipboard.on('success', function(e) {
          e.clearSelection();
        });

        clipboard.on('error', function(e) {
          console.error('Action:', e.action);
          console.error('Trigger:', e.trigger);
        });
      });
    };

    initClipboard();

    $(document).on('handlebars.render.end', function () {
      initClipboard();
    });
  }
}
