'use strict';

import { tns } from 'tiny-slider/src/tiny-slider';

export default class Carousel {
  constructor() {
    const initTinySlider = () => {
      $('[data-carousel]:not([data-has-carousel])').each(function () {
        $(this).data('has-carousel', true);

        $(this).attr('data-has-carousel', true);

        tns({
          container: this,
          swipeAngle: 70,
          ...$(this).data('carousel') || {}
        });
      });
    };

    initTinySlider();

    $(document).on('handlebars.render.end', initTinySlider);
  }
}
