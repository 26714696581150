'use strict'

export default class Bookmark {
  constructor () {
    $.extend({
      bookmark: {
        render: ($container=$('body'), bookmarks=null) => {
          if (bookmarks !== null) {
            $.bookmark.renderIcons($container, bookmarks);
            return;
          }

          const endpoints = {};

          $container.find('[data-bookmark-icon]').each(function () {
            const config = $(this).data('bookmark-icon');

            if (!endpoints[config.url]) {
              endpoints[config.url] = [];
            }

            endpoints[config.url].push(config.id.toString());
          });

          Object.entries(endpoints).forEach(function ([url, toRender]) {
            $.ajax({
              url,
              contentType: 'application/json',
              data: JSON.stringify(toRender),
              method: 'post',
              success: (res) => {
                $.bookmark.renderIcons($container, res)
              }
            })
          })
        },
        renderIcons: ($container, bookmarks) => {
          $container.find('[data-bookmark-icon]').each(function () {
            const config = $(this).data('bookmark-icon');
            if (bookmarks[config.id] === undefined) {
              return;
            }

            const icon = bookmarks[config.id] ? config.iconBookmark : config.iconNotBookmark;

            $(this).html(icon);
          });
        }
      }
    });

    $.bookmark.render()

    $(document).on('handlebars.render.end', function(e, $container) {
      $.bookmark.render($container)
    });
  }
}
