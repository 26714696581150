// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import '@fortawesome/fontawesome-free/js/all.js';
import 'lightbox2';

import Commons, { CommonsFooter } from './commons';
import Dropdown from './_dropdown';
import JQueryHandlebars from './_handlebars';
import Header from '../_modules/header/header';
import SearchBlock from '../_modules/search-block/search-block';
import TextBlock from '../_modules/text-block/text-block';
import InfiniteScroll from '../_modules/infinite-scroll/infinite-scroll';
import RangePicker from '../_modules/range-picker/range-picker';
import Chart from '../_modules/chart/chart';
import Modal from '../_modules/modal/modal';
import From from '../_modules/form/form';
import MapBlock from '../_modules/map-block/map-block';
import Card from '../_modules/card/card';
import Clipboard from '../_modules/clipboard/clipboard';
import Carousel from '../_modules/carousel/carousel';
import GotoTop from '../_modules/goto-top/goto-top';
import Table from '../_modules/table/table';
import MusicSheet from '../_modules/music-sheet/music-sheet';
import NavigationCache from '../_modules/navigation-cache/navigation-cache';
import VerticalNavigation from '../_modules/vertical-navigation/vertical-navigation';
import Periodical from '../_modules/periodical/periodical';
import List from '../_modules/lists/list';
import Radar from '../_modules/radar/radar';
import Highlight from '../_modules/highlight/highlight';
import Autocomplete from '../_modules/autocomplete/autocomplete';
import Toggler from './_toggler';
import Bookmark from '../_modules/bookmark/bookmark';

$(() => {
  new JQueryHandlebars();
  new Dropdown();
  new Header();
  new Commons();
  new SearchBlock();
  new TextBlock();
  new RangePicker();
  new Chart();
  new Modal();
  new From();
  new MapBlock();
  new Card();
  new Clipboard();
  new Carousel();
  new GotoTop();
  new Table();
  new MusicSheet();
  new NavigationCache();
  new VerticalNavigation();
  new Periodical();
  new List();
  new Radar();
  new Highlight();
  new Autocomplete();
  new CommonsFooter();
  new InfiniteScroll();
  new Bookmark();
  new Toggler();
});
