'use strict';

export default class JQueryHelpers {
  constructor() {
    $('body').on('click', '[data-toggle="class"]', function(e) {
      e.preventDefault();

      const $target = $($(this).data('target') || $(this).attr('href'));

      const classNames = $(this).data('class');

      const $alternatedItemd = $(this).find('[data-alternated-text]');

      classNames.split(', ').forEach(className => {
        $target.toggleClass(className);
      });

      if ($alternatedItemd.length) {
        const currentText = $alternatedItemd.text();

        const alternatedText = $alternatedItemd.data('alternated-text');

        $alternatedItemd.data('alternated-text', currentText);

        $alternatedItemd.attr('data-alternated-text', currentText);

        $alternatedItemd.text(alternatedText);
      }
    });

    $('body').on('change', '[data-toggle="remove"]', function() {
      $($(this).data('target')).remove();
    });

    $('body').on('click', '[data-toggle="group-items"]', function(e) {
      e.preventDefault();

      const $groupList = $(this).closest('.list-group').length ? $(this).closest('.list-group') : $(this).closest('[data-list-group]');

      const isToggled = $(this).data('toggled');

      $(this).data('toggled', !isToggled);

      $(this).attr('data-toggled', !isToggled);

      $(this).find('[data-not-toggled]')[(!isToggled ? 'add' : 'remove') + 'Class']('d-none');

      $(this).find('[data-toggled]')[(isToggled ? 'add' : 'remove') + 'Class']('d-none');

      $groupList.find('[data-to-toggle="true"]').toggleClass($(this).data('to-toggle'));
    });

    $('body').on('click', '[data-remove-parent]', function(e) {
      e.preventDefault();

      const $parent = $(this).closest($(this).data('remove-parent'));

      const $parentsParent = $parent.parent();

      $parent.animate({
        opacity: 0
      }, 300, () => {
        $parent.remove();

        if (!$parentsParent.children().length) {
          $parentsParent.parent().addClass('d-none');
        }
      });
    });

    const getDuplicateIds = () => {
      const elements = [...document.querySelectorAll('[id]')];

      const ids = [];

      const dups = [];

      elements.map(el => ids.includes(el.id) ? dups.push(el) : ids.push(el.id));

      if (dups.length) {
        console.warn('Ci sono degli ID dupplicati', dups);
      }
    }

    getDuplicateIds();

    const initTooltip = () => {
      $('[data-toggle="tooltip"]:not([data-has-tooltip])').each(function() {
        $(this).data('has-tooltip');

        $(this).attr('data-has-tooltip');

        $(this).tooltip();
      });

      $('[data-toggle="popover"]:not([data-has-popover])').each(function() {
        $(this).data('has-popover');

        $(this).attr('data-has-popover');

        $(this).popover();
      });
    };

    initTooltip();

    $(document).on('handlebars.render.end', () => {
      getDuplicateIds();

      initTooltip();
    });

    setTimeout(() => {
      if ($('.divContainerSidebar:visible').length) {
        $('body').addClass('sidebar-open');
      }
    }, 50);
  }
}
