'use strict';

import Splide from '@splidejs/splide';

export default class SplideCarousel {
  constructor() {
    const initSplideCarousel = () => {
      if( $('.splide:not([has-splide])').length ){
        var splide = new Splide( '.splide', {
          type   : 'slide',
          rewind: true,
          perPage: 3,
          perMove: 1,
        } );

        splide.mount();

        $('.splide').attr('has-splide', true);
      }
    }

    initSplideCarousel();

    $(document).on('handlebars.render.end', function() {
      initSplideCarousel();
    });
  }
};
