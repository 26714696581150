'use strict';

import camelCase from 'lodash/camelCase';

export default class Togglers {
  constructor() {
    const togglers = {
      tab: ($obj, e, eventParams) => {
        if (!eventParams.fakeClick) {
          $obj.closest('[role="tablist"]').data('tabs-clicked', true).attr('data-tabs-clicked', true);
        }
      },
      class: ($obj, e, eventParams, togglerParams) => {
        const { target, classes, method } = togglerParams;

        $(target)[method + 'Class'](classes);
      },
      click: ($obj, e, eventParams, togglerParams) => {
        const $target = $(togglerParams.target || $obj.data('target') || $obj);

        $target.trigger('click');
      },
      hover: ($obj, e, eventParams, togglerParams) => {
        const $target = $(togglerParams.target || $obj.data('target'));

        $target.trigger('mouseover');
      },
      nextTab: ($obj, e, eventParams, togglerParams) => {
        if (e) {
          e.preventDefault();
        }

        const $parent = $obj.closest(togglerParams.target || $obj.data('target'));

        const $tabs = $parent.find('[data-toggle="tab"]');

        if ($tabs.closest('[role="tablist"]').data('tabs-clicked')) {
          return;
        }

        let nextIndex = 0;

        $tabs.each(function(index) {
          if ($(this).hasClass('active')) {
            nextIndex = index + 1;
          }
        });

        if ($tabs.length > nextIndex) {
          $($tabs[nextIndex]).trigger('click', [{
            fakeClick: true
          }]);
        }
      },
      multiple: ($obj, e, eventParams, isHover) => {
        $obj.data('togglers').forEach(toggler => {
          const togglerName = camelCase(toggler.name);

          if (!togglers[togglerName] || (isHover && !toggler.onHover)) {
            return;
          }

          togglers[togglerName]($obj, e, eventParams, toggler.params || {});
        });
      }
    }

    const initToggler = ($obj, e, eventParams, isHover) => {
      const togglerName = camelCase($obj.data('toggle') || $obj.data('toggle-once'));

      $obj.data('has-toggle-once', true);

      $obj.attr('data-has-toggle-once', true);

      if (!togglers[togglerName]) {
        return;
      }

      togglers[togglerName]($obj, e, eventParams, isHover);
    }

    $('body').on('click', '[data-toggle], [data-toggle-once]:not([data-has-toggle-once])', function(e, eventParams) {
      initToggler($(this), e, eventParams || {});
    });

    $('body').on('mouseover', '[data-toggle]', function(e, eventParams) {
      initToggler($(this), e, eventParams || {}, true);
    });

    $(document).on('handlebars.render.end', function() {
      $('[data-toggle-once]:not([data-has-toggle-once])').each(function() {
        initToggler($(this), null, {});
      });
    });
  }
};
