'use strict';

import InViewport from './in-viewport';
import JQueryHelpers from './jquery';
import Togglers from './togglers';
import SplideCarousel from './splide';

export default class Commons {
  constructor() {
    new InViewport();
    new JQueryHelpers();
    new SplideCarousel();
  }
}

export class CommonsFooter {
  constructor() {
    new Togglers();
  }
}
