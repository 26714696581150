'use strict';

export default class Card {
  constructor() {
    const initFullHeight = () => {
      $('[data-fh]').each(function() {
        const maxHeight = $(this).data('fh') || 'none';

        $(this).css({
          height: 0
        });

        const parentHeight = $(this).parent().height();

        $(this).css({
          height: parentHeight || maxHeight
        });

        if (maxHeight === 'auto' || parentHeight > maxHeight) {
          return $(this).css({
            maxHeight: parentHeight
          });
        }

        $(this).css({
          maxHeight,
          minHeight: maxHeight
        });
      });
    };

    const showIfNotEmpty = () => {
      $('[data-show-if-not-empty]').each(function() {
        const $parent = $(this).closest('[data-results-container]');

        const count = parseInt($parent.find('[data-results-count]').data('results-count'));

        $(this)[(count ? 'remove' : 'add') + 'Class']('d-none');
      });
    };

    showIfNotEmpty();

    initFullHeight();

    $(document).on('handlebars.render.end', () => {
      showIfNotEmpty();

      initFullHeight();
    });

    $('body').on('hidden.bs.collapse shown.bs.collapse', '.collapse', initFullHeight);

    $(window).on('resize', initFullHeight);

    $('body').on('click', '[data-toggle="theme"]', function() {
      const themeClasses = $(this).closest('.card').attr('class').split(' ').filter(className => {
        return className.startsWith('card-theme') || className.startsWith('theme-');
      });

      const changeTheme = ($target) => {
        $target.removeClass((index, classes) => {
          return classes.split(' ').filter(className => {
            return className.startsWith('card-theme') || className.startsWith('theme-');
          }).join(' ');
        }).addClass(themeClasses.join(' '));
      };

      setTimeout(() => {
        const $target = $($(this).data('target') || $(this).attr('href'));

        changeTheme($target)
      }, 100);
    });

    $('body').on('click', '[data-fullscreen]', function(e){
      e.preventDefault();

      $('body').toggleClass('has-fullscreen');

      $(this).children().toggleClass('d-none');

      $('[data-infinite-scroll-results-container], [data-infinite-scroll-results-container] .fh').css({
        minHeight: $('body').hasClass('has-fullscreen') ? '1200px' : '0'
      });

      initFullHeight();
    })

    $('body').on('click', '[data-trigger="post"]', function (e) {
      e.preventDefault();

      const $target = $($(this).attr('href') || $(this).data('target'));

      const items = [];

      const allUnchecked = !$target.find('[data-to-post-item]:checked').length;

      $target.find('[data-to-post-item]').each(function () {
        if (allUnchecked || ($(this).is(':checkbox') && $(this).is(':checked'))) {
          items.push($(this).data('to-post-item'));
        }
      });

      const $input = $('<input>', {
        name: $(this).data('name') || 'items'
      });

      $input.val(JSON.stringify(items));

      const $form = $('<form>', {
        action: $(this).data('url'),
        method: 'post',
        target: $(this).attr('target')
      });

      $form.html($input);

      $('body').append($form);

      $form.submit();
    });
  }
}
