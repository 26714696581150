'use strict';

import _ from 'lodash';
import md5 from 'blueimp-md5';
import { objToStyles } from '../../_scripts/commons/helpers';

export default class Radar {
  constructor() {
    ($ => {
      $.extend({
        radar: {
          radars: {},
          hexToRgba: (hex, alpha) => {
            const r = parseInt(hex.slice(1, 3), 16);

            const g = parseInt(hex.slice(3, 5), 16);

            const b = parseInt(hex.slice(5, 7), 16);

            return 'rgba(' + [
              r,
              g,
              b,
              alpha || 1
            ].join(', ') + ')';
          },
          tooltipTimeout: null,
          infowindowTimeout: null,
          getLevelItemOffset: $obj => {
            const objOffset = $.radar.getLevelItemRect($obj);

            const toReturn = {
              top: 0,
              left: 0
            };

            const objR = objOffset.width / 2;

            toReturn.top = objOffset.top;

            toReturn.left = objOffset.left + (objR / 2);

            return toReturn;
          },
          tooltip: {
            id: 'radar-tooltip',
            closeAll: () => {
              $('[data-level-item]:not([data-level-item-active])').each(function() {
                $.radar.tooltip.close($(this));
              });
            },
            close: $obj => {
              const tooltipSelector = $obj ? '#' + [
                $.radar.tooltip.id,
                $obj.data('level-item-id')
              ].join('---') : '[data-radar-tooltip]';

              $(tooltipSelector).remove();
            },
            init: $obj => {
              const objOffset = $.radar.getLevelItemOffset($obj);

              const itemConfig = $obj.data('level-item');

              const tooltipId = [
                $.radar.tooltip.id,
                $obj.data('level-item-id')
              ].join('---');

              const $tooltip = $('#' + tooltipId).length ? $('#' + tooltipId) : $('<div>', {
                id: tooltipId,
                class: 'radar-tooltip',
                'data-radar-level-item-id': $obj.data('level-item-id'),
                'data-radar-tooltip': true
              });

              $tooltip.html(itemConfig.info.title)

              $('body').append($tooltip);

              $tooltip.css({
                top: objOffset.top - $tooltip.height() + $obj.height(),
                left: objOffset.left - ($tooltip.width() / 2),
              });

              $tooltip.addClass('animate');
            }
          },
          getLevelItemRect: $obj => {
            const objRect = $obj.get(0).getBoundingClientRect();

            return {
              top: objRect.top + $(window).scrollTop(),
              left: objRect.left,
              width: objRect.width,
              height: objRect.height
            };
          },
          infowindow: {
            getPlacement: ($obj, $infowindow) => {
              const objOffset = $.radar.getLevelItemRect($obj);

              const iwWidth = $infowindow.outerWidth();

              const iwHeight = $infowindow.outerHeight();

              const levelItem = $obj.data('level-item');

              const radarId = levelItem.path[0];

              const $parent = $obj.closest('.radar-container');

              const parentOffset = $parent.offset();

              const {
                from,
                to
              } = $.radar.radars[radarId].sections[levelItem.section].placement;

              const bounds = {
                topLeft: {
                  x: objOffset.left - parentOffset.left,
                  y: objOffset.top - parentOffset.top
                },
                bottomRight: {
                  x: objOffset.left - parentOffset.left + (objOffset.width * 2),
                  y: objOffset.top - parentOffset.top + (objOffset.height * 2)
                }
              };

              let y = 'top';

              if (bounds.topLeft.y + iwHeight > $parent.height() / 2) {
                y = 'bottom';
              }

              let x = 'left';

              if (bounds.topLeft.x + iwWidth > $parent.width() / 2) {
                x = 'right';
              }

              return [
                y,
                x,
              ].join('-')
            },
            close: () => {
              const infowindowId = 'radar-infowindow';

              const $iWItem = $('[data-level-item-with-infowindow]');

              if ($iWItem.length) {
                $iWItem.removeData('level-item-with-infowindow');

                $iWItem.removeAttr('data-level-item-with-infowindow');
              }


              $('#' + infowindowId).remove();
            },
            init: $obj => {
              const objOffset = $.radar.getLevelItemRect($obj);

              $obj.data('level-item-with-infowindow', true);

              $obj.attr('data-level-item-with-infowindow', true);

              $.radar.tooltip.close($obj);

              const objR = objOffset.width / 2;

              objOffset.top = objOffset.top + objR;

              objOffset.left = objOffset.left + objR;

              objOffset.top = parseInt(objOffset.top) + (parseInt(objOffset.top) < objOffset.top ? 1 : 0);

              objOffset.left = parseInt(objOffset.left) + (parseInt(objOffset.left) < objOffset.left ? 1 : 0);

              const itemConfig = $obj.data('level-item');

              const infowindowId = 'radar-infowindow';

              const {
                radarConfig
              } = $.radar.radars[itemConfig.radarId]

              if ($('#' + infowindowId).length) {
                $('#' + infowindowId).remove();
              }

              const $infowindow = $('<div>', {
                id: infowindowId,
                class: 'radar-infowindow'
              });

              if (!open) {
                if ($.radar.infowindowTimeout) {
                  clearTimeout($.radar.infowindowTimeout);
                }

                return $infowindow.animate({
                  opacity: 0,
                }, 150, () => {
                  $infowindow.remove();
                });
              }

              $infowindow.html($.handlebars({
                returnHtml: true,
                context: {
                  ...((radarConfig.infowindow || {}).context || {}),
                  ...itemConfig.info
                },
                template: (radarConfig.infowindow || {}).template || '#radar-infowindow-template'
              }));

              $('body').append($infowindow);

              const placement = $.radar.infowindow.getPlacement($obj, $infowindow.find('.radar-infowindow-content'));

              $infowindow.addClass('radar-infowindow-' + placement);

              $infowindow.addClass('animate');

              $infowindow.css({
                top: objOffset.top,
                left: objOffset.left
              });
            }
          },
          polarToCartesian: (centerX, centerY, radius, angleInDegrees) => {
            const angleInRadians = (angleInDegrees - 90) * Math.PI / 180;

            return {
              x: centerX + (radius * Math.cos(angleInRadians)),
              y: centerY + (radius * Math.sin(angleInRadians))
            };
          },
          describeArc: (x, y, radius, startAngle, endAngle) => {
            const start = $.radar.polarToCartesian(x, y, radius, endAngle);

            const end = $.radar.polarToCartesian(x, y, radius, startAngle);

            const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

            const d = [
              'M', start.x, start.y,
              'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
            ].join(' ');

            return d;
          },
          getPointAtLength: (path, percentage) => {
            const pathLength = Math.floor(path.getTotalLength());

            percentage = (percentage * pathLength) / 100;

            // Get x and y values at a certain point in the line
            const pt = path.getPointAtLength(percentage);

            return {
              x: Math.round(pt.x),
              y: Math.round(pt.y)
            };
          },
          setConnctions: ($obj, exclude, $origin, connectionsStyles) => {
            if (!$obj.length) {
              console.log($obj, 'non trovato');

              return;
            }

            const {
              refs,
              path
            } = $obj.data('level-item') || {};

            const radar = $.radar.radars[path[0]];

            const $objOuterCircle = $obj.find('circle:last-child');

            const objOffset = {
              x: $objOuterCircle.attr('cx'),
              y: $objOuterCircle.attr('cy')
            };

            if ($origin) {
              $.radar.tooltip.init($obj);
            }

            Object.entries(refs || {}).forEach(([key, valueRefs]) => {
              (_.isArray(valueRefs) && valueRefs || []).forEach(ref => {
                const $currentObj = $('[data-level-item-id="' + ref + '"]');

                if (!$currentObj.length) {
                  console.log('Ref', ref, 'non trovato');

                  return;
                }

                const isCurrentObj = ($origin || $obj).get(0) === $currentObj.get(0);

                if (exclude && exclude === key && !isCurrentObj) {
                  return;
                }

                if (!isCurrentObj) {
                  $.radar.tooltip.init($currentObj);
                }

                const $outerCircle = $('[data-level-item-id="' + ref + '"] circle:last-child');

                const refOffset = {
                  x: parseFloat($outerCircle.attr('cx')),
                  y: parseFloat($outerCircle.attr('cy'))
                };

                const line = radar.draw.line(
                  objOffset.x,
                  objOffset.y,
                  objOffset.x,
                  objOffset.y
                ).stroke(connectionsStyles);

                line.insertAfter(radar.lastPath);

                line.animate(150, 0, 'now').attr({
                  x2: refOffset.x,
                  y2: refOffset.y
                });

                line.attr({
                  'data-level-line': true
                });
              })
            });
          },
          activeRefs: ($obj, exclude, $origin) => {
            const {
              path,
              section,
              refs,
              id
            } = $obj.data('level-item') || {};

            const radar = $.radar.radars[path[0]];

            const {
              refs: sectionRefs
            } = (((radar || {}).sections || {})[section] || {}).section;

            if (sectionRefs) {
              return $obj.closest('[data-radar]').find('[data-level-item-section="' + sectionRefs + '"]').each(function() {
                const levelItemConfig = $(this).data('level-item') || {};

                if (((levelItemConfig.refs || {})[section] || []).find(item => {
                  return item === id;
                })) {
                  $.radar.activeRefs($(this), section, $obj);
                }
              });
            }

            if (exclude) {
              $.radar.activeLevelItem($obj, false, false, true);
            }

            if (radar.radarConfig.connections && radar.radarConfig.connections.active) {
              $.radar.setConnctions($obj, exclude, $origin, radar.radarConfig.connections.styles);
            }

            Object.entries(refs || {}).forEach(([key, items]) => {
              if (key !== '__id' && key !== exclude) {
                items.forEach(item => {
                  $.radar.activeLevelItem($obj.closest('[data-radar]').find('[data-level-item-id="' + item + '"]'), false, false, true);
                });
              }
            });
          },
          resetLevelItems: $obj => {
            $('[data-level-line]').remove();

            ($obj ? $obj.closest('[data-radar]') : $('[data-radar]')).find('[data-level-item-active]').map(function() {
              if (!$obj || this !== $obj.get(0)) {
                $.radar.resetLevelItem($(this));
              }
            });
          },
          resetLevelItem: $obj => {
            const levelItemConfig = $obj.data('level-item');

            const link = _.get($.radar.radars, levelItemConfig.path);

            link.circle.animate(150, 0, 'now').attr({
              r: 5
            });

            link.circleA.animate(150, 0, 'now').attr({
              r: 0,
              opacity: 0,
            });

            $obj.removeData('level-item-active').removeAttr('data-level-item-active');

            $obj.removeData('level-item-with-infowindow').removeAttr('data-level-item-with-infowindow');
          },
          activeLevelItem: ($obj, fromHover, fromClick, fromRef) => {
            if (!$obj.length) {
              console.log($obj, 'non trovato');

              return;
            }

            const levelItemConfig = $obj.data('level-item');

            if (!levelItemConfig) {
              return;
            }

            const link = _.get($.radar.radars, levelItemConfig.path);

            const isActiveItem = !(!$obj.data('level-item-active'));

            const animationConfig = {
              duration: 150,
              when: 'now'
            };

            link.circle.animate(animationConfig).attr({
              r: 4
            });

            link.circleA.animate(animationConfig).attr({
              r: fromHover && !isActiveItem ? 7 : 9,
              opacity: 0.8,
            });

            if (fromHover) {
              if ($.radar.tooltipTimeout) {
                clearTimeout($.radar.tooltipTimeout);
              }

              $.radar.tooltipTimeout = setTimeout(() => {
                $.radar.tooltip.init($obj);
              }, 150);
            }

            if (fromClick || fromRef) {
              if (fromClick) {
                $.radar.tooltip.close();

                $.radar.resetLevelItems($obj);
              }

              $obj.data('level-item-active', true);

              $obj.attr('data-level-item-active', true);

              if (!fromRef) {
                if ($.radar.infowindowTimeout) {
                  clearTimeout($.radar.infowindowTimeout);
                }

                $.radar.infowindowTimeout = setTimeout(() => {
                  $.radar.infowindow.init($obj);
                }, 150);

                $.radar.activeRefs($obj);
              }
            }
          },
          setLevelItem: (draw, section, level, levelItem, perc, path, radarId, isFirst) => {
            const link = draw.link('#');

            const radar = $.radar.radars[radarId];

            link.attr({
              'href': '#',
              'data-level-item-id': levelItem.id,
              'data-level-item-section': levelItem.section,
              'data-level-item': JSON.stringify({
                ...levelItem,
                path,
                radarId
              })
            });

            const circlePosition = $.radar.getPointAtLength(level.path.node, perc);

            const circleAttrs = {
              cx: circlePosition.x,
              cy: circlePosition.y,
              href: '#' + level.pathId,
              class: 'level-item-inner',
              fill: section.color
            }

            const circle = link.circle(10);

            circle.attr(circleAttrs);

            const circleA = link.circle(0);

            circleA.attr({
              ...circleAttrs,
              class: 'level-item-outer',
              fill: 'transparent',
              stroke: section.color,
              opacity: 0,
              strokeWidth: 1
            });

            const circleB = link.circle(22);

            circleB.attr({
              ...circleAttrs,
              class: 'level-item-outer',
              fill: 'transparent',
              opacity: 0,
              strokeWidth: 0
            });

            if (radar.radarConfig.variant === 2) {
              const $container = $('[data-radar-id="' + radarId + '"]');

              let $tooltipsContainer = $container.find('[data-radar-tooltips]');

              if (!$tooltipsContainer.length) {
                $tooltipsContainer = $('<div>', {
                  'data-radar-tooltips': true
                });
              }

              if (isFirst) {
                $tooltipsContainer.html('');
              }

              $tooltipsContainer.append($('<span>', {
                style: objToStyles({
                  position: 'absolute',
                  width: 0,
                  height: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  top: circlePosition.y + 'px',
                  left: circlePosition.x + 'px',
                  zIndex: 1
                })
              }).html($('<span>', {
                style: objToStyles({
                  whiteSpace: 'nowrap',
                  fontSize: '12px',
                  marginBottom: '30px'
                })
              }).html(levelItem.info.title)));
            }

            _.set($.radar.radars, path, {
              link,
              circle,
              circleA
            });
          },
          setLevelItems: (levelItems, sectionId, radarId, isFirst) => {
            const radar = $.radar.radars[radarId];

            const {
              variant = 1
            } = radar.radarConfig

            const section = radar.sections[sectionId];

            Object.entries(levelItems).forEach(([levelId, levelItems]) => {
              const newLevelId = variant === 1 ? levelId * 2 : (levelId * 2) - 1

              const level = ((section || {}).levels || {})[newLevelId];

              if (!level) {
                return;
              }

              const perc = 100 / (levelItems.length + 1);

              levelItems.forEach((levelItem, index) => {
                const percToAdd = parseInt(levelId) % 2 ? 2 : -2;

                $.radar.setLevelItem(radar.draw, section.section, level, levelItem, (perc * (index + 1)) + percToAdd, [
                  radarId,
                  'sections',
                  sectionId,
                  'levels',
                  levelId,
                  'items',
                  index
                ], radarId, isFirst);

                isFirst = false;
              });
            });
          },
          setSectionItems: (sectionItems, radarId) => {
            let isFirst = true;

            Object.entries(sectionItems).forEach(([sectionId, setionItems]) => {
              if (sectionId !== '__id') {
                $.radar.setLevelItems(setionItems, sectionId, radarId, isFirst);

                isFirst = false;
              }
            });
          },
          getFirstFreeLevel: (values, level, maxPerLevel) => {
            values = values.filter(value => !(!value));

            const levelItems = values.filter(currentValue => {
              return currentValue.level === level;
            });

            if (levelItems.length > maxPerLevel - 1) {
              return $.radar.getFirstFreeLevel(values, level + 1, maxPerLevel);
            }

            return level;
          },
          orderToLevel: (values, levels, maxPerLevel) => {
            values = values.filter(value => !(!value));

            values.sort((a, b) => {
              if (a.order > b.order) {
                return 1;
              }

              if (a.order < b.order) {
                return -1;
              }

              return 0;
            });

            let lastOrder = 0;

            let lastRelOrder = 0;

            values = values.map(value => {
              if (value.order !== lastOrder) {
                lastOrder = value.order;

                lastRelOrder++;
              }

              value.order = lastRelOrder;

              return value;
            });

            const max = (_.maxBy(values, 'order') || {}).order || 0;

            const min = (_.minBy(values, 'order') || {}).order || 0;

            const levelSize = (max - min) / levels;

            values.forEach(value => {
              const level = value.level || ((max - value.order) / levelSize) || 1;

              value.level = $.radar.getFirstFreeLevel(values, parseInt(level) < level ? parseInt(level) + 1 : parseInt(level), maxPerLevel);
            });

            return values;
          },
          init: $obj => {
            const radarId = _.uniqueId('radar_');

            $obj.data('has-radar', true);

            $obj.attr('data-has-radar', true);

            $obj.data('radar-id', radarId);

            $obj.attr('data-radar-id', radarId);

            $.radar.infowindow.close();

            $.radar.tooltip.close();

            const $radarContainer = $obj.find('[data-radar-container]');

            const radarConfig = {
              radarSize: 700,
              ...$obj.data('radar')
            };

            const parentWidth = $obj.parent().innerWidth();

            if (parentWidth < radarConfig.radarSize) {
              radarConfig.radarSize = parentWidth;
            }

            const halfRadarSize = radarConfig.radarSize / 2;

            const draw = SVG().addTo($radarContainer.get(0));

            radarConfig.padding = (halfRadarSize / 10) + 5;

            const centerPadding = 10;

            const centerSize = radarConfig.padding + centerPadding;

            radarConfig.levelSize = (halfRadarSize - (radarConfig.padding / 2) - ((centerSize * 2) - centerPadding)) / (radarConfig.levels * 2);

            draw.size(radarConfig.radarSize, radarConfig.radarSize);

            $.radar.radars[radarId] = {
              draw,
              radarConfig,
              sections: {}
            };

            let lastPath = null;

            radarConfig.sections.forEach((section, index) => {
              const arcSize = 360 / radarConfig.sections.length;

              let from = index * arcSize;

              switch (radarConfig.variant) {
                case 2:

                  from = from - (arcSize / 2)

                  break;
                default:
                  break;
              }

              const to = from + arcSize;

              $.radar.radars[radarId].sections[section.id] = {
                section,
                levels: {}
              };

              for (let i = 0; i <= radarConfig.levels * 2; i++) {
                const levelSize = !i ? i * radarConfig.levelSize :  (i * radarConfig.levelSize) + 10

                const radius = (halfRadarSize - levelSize) - radarConfig.padding;

                const pathId = [
                  radarId,
                  'path',
                  index,
                  i
                ].join('-');

                const alpha = i * (0.5 / (radarConfig.levels * 2));

                const padding = 1.5;

                const pathValue = $.radar.describeArc(halfRadarSize, halfRadarSize, radius, from + padding, to - padding);

                const path = draw.path(pathValue)

                path.attr({
                  fill: 'none',
                  stroke: i % 2 ? $.radar.hexToRgba(section.color, 0.2 + alpha) : 'transparent',
                  'stroke-width': 1,
                  id: pathId,
                  class: 'radar-arch'
                });

                lastPath = path;

                if (!i) {
                  const centerPath = $.radar.getPointAtLength(path.node, 50);

                  const sectionPlacement = {
                    radar: {
                      offset: $obj.offset(),
                      width: $obj.width(),
                      height: $obj.height()
                    },
                    from: $.radar.getPointAtLength(path.node, 0),
                    to: $.radar.getPointAtLength(path.node, 100)
                  };

                  $.radar.radars[radarId].sections[section.id].placement = sectionPlacement;

                  let textRotation = (arcSize * (index + 1));

                  switch (radarConfig.variant) {
                    case 2:
                      textRotation = textRotation - arcSize;

                      break;
                    default:
                      textRotation = textRotation - (arcSize / 2);

                      break;
                  }

                  if (textRotation > 90 && textRotation < 270) {
                    textRotation += 180;
                  }

                  const $labelNode = $('<div>', {
                    class: 'radar-label'
                  });

                  $labelNode.html($('<div>').html(section.label));

                  $labelNode.css({
                    top: centerPath.y,
                    left: centerPath.x,
                    color: section.color,
                    transform: 'rotate(' + textRotation + 'deg)'
                  });

                  $radarContainer.append($labelNode);
                } else {
                  $.radar.radars[radarId].sections[section.id].levels[(radarConfig.levels * 2) - (i - 1)] = {
                    path,
                    pathValue,
                    pathId
                  }
                }
              }
            });

            const centerCircle = draw.circle(centerSize * 2);

            centerCircle.attr({
              'data-level-items-reset': true,
              cx: '50%',
              cy: '50%',
              fill: 'transparent',
            });

            $.radar.radars[radarId].lastPath = lastPath;

            //TODO separare gestione items
            const items = _.groupBy(radarConfig.items.map(item => {
              if (item && !item.idParsed) {
                item.id = md5(item.id);

                item.idParsed = true;

                if (item.refs) {
                  Object.entries(item.refs).forEach(([key, values]) => {
                    if (key !== '__id') {
                      item.refs[key] = values.map(value => {
                        return md5(value);
                      });
                    }
                  });
                }
              }

              return item;
            }), 'section');

            Object.entries(items).forEach(([key, values]) => {
              if (key !== '__id') {
                items[key] = _.groupBy($.radar.orderToLevel(values, radarConfig.levels, radarConfig.maxPerLevel), 'level');
              }
            });

            $.radar.setSectionItems(items, radarId);
          }
        }
      });
    })($);

    const initRadar = () => {
      $('[data-radar]:not([data-has-radar])').each(function() {
        $.radar.init($(this));
      });
    };

    initRadar();

    $(document).on('handlebars.render.end', _.debounce(initRadar, 100));

    let preventHover = false;

    $('body').on('mouseover touchstart', '[data-level-item]:not([data-level-item-active]):not([data-level-item-with-infowindow])', function(e) {
      if (e.type === 'touchstart') {
        return preventHover = true;
      }

      if (!preventHover) {
        $.radar.tooltip.closeAll();

        $.radar.activeLevelItem($(this), true);
      }

      preventHover = false;
    });

    $('body').on('mouseleave', '[data-level-item]:not([data-level-item-active]):not([data-level-item-with-infowindow])', function() {
      if (!$(this).data('level-item-active')) {
        $.radar.resetLevelItem($(this));
      }

      $.radar.tooltip.close($(this));
    });

    $('body').on('click', '[data-level-item]', function(e) {
      e.preventDefault();

      if (!$(this).data('level-item-with-infowindow')) {
        $.radar.activeLevelItem($(this), false, true);
      }
    });

    $('body').on('click', function(e) {
      const $obj = $(e.target);

      const isInfowindow = $obj.hasClass('radar-infowindow') || $obj.closest('.radar-infowindow').length;

      const isInTooltip = $obj.hasClass('radar-tooltip') || $obj.closest('.adar-tooltip').length;

      const isInLevelItem = $obj.data('level-item') || $obj.closest('[data-level-item]').length;

      if (!isInfowindow && !isInLevelItem && !isInTooltip) {
        $.radar.infowindow.close();

        $('[data-level-item-active]:not([data-level-item-with-infowindow])').each(function () {
          const $tooltip = $('#' + [
            $.radar.tooltip.id,
            $(this).data('level-item-id')
          ].join('---'));

          if (!$tooltip.length) {
            $.radar.tooltip.init($(this));
          }
        });
      }
    });

    $('body').on('click', '[data-level-items-reset]', function() {
      $.radar.tooltip.close();

      $.radar.resetLevelItems($(this));
    });

    $('body').on('shown.bs.collapse', '.collapse', function() {
      $(this).find('[data-has-radar]').each(function() {
        $(this).removeAttr('data-has-radar').removeData('has-radar');

        $(this).find('[data-radar-container]').html('');

        initRadar();
      });
    });

    $('body').on('hide.bs.collapse', '.collapse', function() {
      $.radar.tooltip.close();

      $.radar.infowindow.close();

      $.radar.resetLevelItems($(this));
    });

    $('body').on('mouseover', '[data-radar-tooltip]', function() {
      $.radar.tooltip.closeAll();
    });

    $('body').on('click', '[data-radar-tooltip]', function(e) {
      e.preventDefault();

      const $target = $('[data-level-item-id="' + $(this).data('radar-level-item-id') + '"]');

      $.radar.activeLevelItem($target, false, true);
    });

    $('body').on('hide.bs.collapse show.bs.collapse', '.conceptual-map-filters-container.accordion', function(e) {
      $(e.target).prev().find('i').toggleClass('d-none');
    });

    $(window).on('resize', _.debounce(() => {
      $('[data-has-radar]').each(function() {
        $(this).removeAttr('data-has-radar').removeData('has-radar');

        $(this).find('[data-radar-container]').html('');

        initRadar();
      });
    }, 100));
  }
};
