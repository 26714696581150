'use strict';

import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

export default class TextBlock {
  constructor() {
    (function($) {
      $.extend({
        textEffect: {
          init: $obj => {
            $obj.data('has-text-effect', true);

            $obj.attr('data-has-text-effect', true);

            const {
              type
            } = $obj.data('text-effect');

            try {
              $.textEffect[type]($obj);
            } catch (error) {
            }
          },
          typing: $obj => {
            const {
              text,
              removeClass = '',
              addClass = ''
            } = $obj.data('text-effect');

            const letters = text.split('');

            letters.forEach((letter, index) => {
              setTimeout(() => {
                $obj.append(letter);

                if (index === letters.length - 1 && (removeClass || addClass)) {
                  $obj.addClass(addClass);

                  $obj.removeClass(removeClass);
                }
              }, 100 * (index + 1))
            });
          }
        }
      });
    })($);

    const textEffectInit = () => {
      $('[data-text-effect]:not([data-has-text-effect])').each(function() {
        if ($.inViewport($(this))) {
          $.textEffect.init($(this));
        }
      });
    }

    textEffectInit();

    $(window).on('scroll', throttle(() => {
      textEffectInit();
    }, 100));

    $('body').on('click', '[data-toggle="partial-text"]', function(e) {
      e.preventDefault();

      $(this).closest('.text-block-partial').toggleClass('active');
    });

    const checkPartials = () => {
      $('.text-block-partial').each(function() {
        const $textBlock = $(this).children('.text-block');

        const isActive = $(this).hasClass('active');

        $(this).addClass('active');

        let height = $textBlock.height();

        $(this).removeClass('active');

        let maxHeight = $textBlock.css('max-height');

        if (isActive) {
          $(this).addClass('active');
        }

        maxHeight = parseInt(maxHeight.replace('px', ''));

        $(this).find('.text-block-partial-action')[(height <= maxHeight ? 'add' : 'remove') + 'Class']('d-none');
      });
    };

    checkPartials();

    $(window).on('resize', throttle(() => {
      checkPartials();
    }, 100));

    $(document).on('handlebars.render.end', debounce(() => {
      textEffectInit();

      checkPartials();
    }, 200));
  }
}
