'use strict';

import 'jquery-range/jquery.range';

export default class RangePicker {
  constructor() {
    (function($) {
      $.extend({
        rangePicker: {
          init: $obj => {
            let timeout = null;

            let timeoutOthers = null;

            const tmpRangePickerConfig = $obj.data('range-picker') || {};

            const rangePickerConfig = {
              format: '%s',
              width: $obj.parent().width(),
              showLabels: true,
              isRange: true,
              onbarclicked: function() {
                if (timeout) {
                  clearTimeout(timeout);
                }

                timeout = setTimeout(() => {
                  updateRangeItems(this);
                }, 400);
              },
              onstatechange: function(values) {
                if (timeout) {
                  clearTimeout(timeout);
                }

                if (timeoutOthers) {
                  clearTimeout(timeoutOthers);
                }

                timeout = setTimeout(() => {
                  updateRangeItems(this);
                }, 10);

                timeoutOthers = setTimeout(() => {
                  if (tmpRangePickerConfig.updateItems) {
                    const [from, to] = values.split(',').map(item => {
                      return parseInt(item);
                    });

                    Object.entries(tmpRangePickerConfig.updateItems).forEach(([itemType, itemSelector]) => {
                      $(itemSelector).each(function() {
                        const changeValue = $(this).is('input');

                        const currentValue = {
                          from,
                          to
                        }[itemType];

                        const isChanged = changeValue && parseInt($(this).val()) !== currentValue;

                        $(this)[changeValue ? 'val' : 'html'](currentValue);

                        if (isChanged) {
                          $(this).change();
                        }
                      });
                    });
                  }
                }, 100);
              },
              ...tmpRangePickerConfig
            };

            const updateRangeItems = item => {
              const { bar } = item;

              const $backBar = bar.closest('.back-bar');

              let $bars = $backBar.children('.bars');

              let isFirstInside = false;

              let isLastInside = false;

              $bars.children('.bar').each(function(index) {
                const barLeft = parseInt(bar.css('left').replace('px', ''));

                const barRight = parseInt(barLeft + bar.width());

                const barOffset = $(this).offset().left - $bars.offset().left;

                const isOutside = barOffset < barLeft || barOffset > barRight;

                $(this).removeClass('bar-in-range-first');

                $(this).removeClass('bar-in-range-last-but-one');

                $(this).removeClass('bar-in-range-last');

                if (isOutside) {
                  if (isFirstInside && index && !isLastInside) {
                    isLastInside = true;

                    $($bars.children('.bar').get(index - 2)).addClass('bar-in-range-last-but-one');

                    $($bars.children('.bar').get(index - 1)).addClass('bar-in-range-last');
                  }

                  return $(this).removeClass('bar-in-range');
                }

                $(this).addClass('bar-in-range');

                if (!isFirstInside) {
                  isFirstInside = true;

                  $(this).addClass('bar-in-range-first');
                }

                if (index === $bars.children('.bar').length - 1) {
                  $($bars.children('.bar').get(index - 1)).addClass('bar-in-range-last-but-one');

                  $(this).addClass('bar-in-range-last');
                }
              });
            };

            $obj.jRange(rangePickerConfig);

            $.rangePicker.createBars($obj);
          },
          createBars: $obj => {
            const $backBar = $obj.parent().children('.slider-container').children('.back-bar');

            const barsWidth = 6;

            const barsMargin = 3;

            const bars = parseInt($backBar.width() / (barsWidth + (barsMargin * 2)));

            const $bars = $('<div>', {
              class: 'bars'
            });

            $backBar.append($bars);

            for (let i = 0; i < bars; i++) {
              $bars.append($('<div>', {
                class: 'bar'
              }));
            }

            const currentValue = $obj.val();

            $obj.jRange('setValue', '');

            $obj.jRange('setValue', currentValue);
          }
        }
      });
    })($);

    const initRangePicker = () => {
      $('[data-range-picker]:not([data-has-range-picker])').each(function() {
        $(this).data('has-range-picker', true);

        $(this).attr('data-has-range-picker', true);

        $.rangePicker.init($(this));
      });
    };

    initRangePicker();

    $(document).on('handlebars.render.end', function() {
      initRangePicker();
    });



    $('body').on('change', '[data-from-input], [data-to-input]', function () {
      const $container = $(this).closest('.range-picker-container');

      const $from = $container.find('[data-from-input]');

      const $to = $container.find('[data-to-input]');

      const $rangePicker = $container.find('[data-has-range-picker]');

      const step = parseInt($(this).attr('step'));

      const min = parseInt($(this).attr('min'));

      const max = parseInt($(this).attr('max'));

      let value = parseInt($(this).val());

      const isFrom = $(this).data('from-input');

      const reminder = value % step;

      if (reminder) {
        const toAdd = (step / 2 > reminder) ? 0 : step;

        value = (value - reminder) + toAdd;
      }

      if (value > max) {
        value = max;
      }

      if (value < min) {
        value = min;
      }

      $(this).val(value);

      if (isFrom) {
        $to.attr('min', value);
      } else {
        $from.attr('max', value);
      }

      $rangePicker.jRange('setValue', [
        $from.val(),
        $to.val()
      ].join(','));
    });

    $(window).on('resize', function() {
      $('[data-has-range-picker]').each(function() {
        const currentValue = $(this).val();
        $('.slider-container .back-bar .bars').remove();

        $(this).jRange('updateOptions', 'width', $(this).parent().width());

        $.rangePicker.createBars($(this));

        $(this).jRange('setValue', '');

        $(this).jRange('setValue', currentValue);
      });
    });
  }
}
