'use strict';

import L from 'leaflet';
import 'leaflet.markercluster';
import { GestureHandling } from 'leaflet-gesture-handling';
import uniqueId from 'lodash/uniqueId'

export default class MapBlock {
  constructor() {
    $.extend({
      mapBlock: {
        maps: {},
        init: ($obj) => {
          $obj.data('has-map', true);

          $obj.attr('data-has-map', true);

          const mapConfig = $obj.data('map-config');

          var myPosition =  null;
          if (mapConfig.lat != null && mapConfig.lon != null) {
            myPosition =  [mapConfig.lat, mapConfig.lon];
          }
          
          const mapCache = $.mapBlock.maps[mapConfig.id] || {
            map: null,
            markers: {}
          }

          if (mapCache.map) {
            return console.log('MapID', mapConfig.id, 'già presente nella pagina');
          }

          L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

          const icon = L.icon({
            iconUrl: sriConfig.marker,
            iconSize: [25, 41],
            iconAnchor: [12.5, 41],
            popupAnchor: [0, -41]
          });

          const markers = new L.MarkerClusterGroup({
            disableClusteringAtZoom: 15
          });

          (mapConfig.markers || []).forEach(marker => {
            const currentMarker = L.marker(marker.coords, {
              icon
            });

            const markerHtml = mapConfig.popupTemplate ? $.handlebars({
              template: mapConfig.popupTemplate,
              context: mapConfig.asFilters ? {
                info: marker.info,
                asFilters: true,
                paramsConfig: {
                  name: mapConfig.name,
                  value: marker.value,
                  infiniteScroll: mapConfig.infiniteScroll
                }
              } : marker.info,
              returnHtml: true
            }) : marker.info;

            currentMarker.bindPopup(markerHtml);

            mapCache.markers[marker.id || uniqueId('marker_')] = {
              marker: currentMarker,
              coords: marker.coords
            }

            markers.addLayer(currentMarker);
          });

          const mapProperties = {
            zoom: 13,
            maxZoom: 18,
            layers: markers,
            gestureHandling: true
          };

          if (mapConfig.markers != null && mapConfig.markers.length === 1) {
            mapProperties.center = mapConfig.markers[0].coords;
          }

          const map = new L.map($obj.get(0), mapProperties);

          L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
          }).addTo(map);

          if(myPosition != null) {
            if (mapConfig.markers != null) {
              mapConfig.markers.push({coords: myPosition});
            }
          }

          if (mapConfig.markers != null && mapConfig.markers.length > 1) {
            map.fitBounds((mapConfig.markers || []).map(marker => {
              return marker.coords;
            }));
          }

          if(myPosition != null) {
            
            const iconMyPosition = L.icon({
              iconUrl: sriConfig.markerMyPosition,
              iconSize: [25, 41],
              iconAnchor: [12.5, 41],
              popupAnchor: [0, -41]
            });      

            L.marker(myPosition, {icon: iconMyPosition})
            .addTo(map)
            .bindPopup(
              $.handlebars({
                template: mapConfig.popupTemplate,
                context: mapConfig.positionLabel ? {title: " ", text: mapConfig.positionLabel} : {title: " ", text: "You are here."},
                returnHtml: true
              })
            )
            .openPopup();

            map.fitBounds(map.getBounds());
          }

          mapCache.map = map;

          $.mapBlock.maps[mapConfig.id] = mapCache;
        },
      }
    });

    $('[data-map-config]:not([data-has-map])').each(function() {
      $.mapBlock.init($(this));
    });

    $(document).on('handlebars.render.end', function() {
      $('[data-map-config]:not([data-has-map])').each(function() {
        $.mapBlock.init($(this));
      });
    });

    $('body').on('shown.bs.tab', '[data-toggle="tab"]', function() {
      const $tabContainer = $($(this).data('target') || $(this).attr('href'));

      $tabContainer.find('[data-has-map]').each(function() {
        const { id } = $(this).data('map-config');

        $.mapBlock.maps[id].map.invalidateSize();
      });
    });

    $('body').on('click', '[data-map-anchor]', function(e) {
      e.preventDefault();

      const mapAnchorConfig = $(this).data('map-anchor');

      const currentMap = $.mapBlock.maps[mapAnchorConfig.mapId] || {};

      if (currentMap.map) {
        const marker = currentMap.markers[mapAnchorConfig.markerId] || null;

        $('body, html').animate({
          scrollTop: $(currentMap.map._container).offset().top - $('.main-header').outerHeight()
        }, 250);

        if (marker) {
          currentMap.map.once('moveend', () => {
            marker.marker.openPopup();
          });

          currentMap.map.setView(marker.coords, 15);
        }
      }
    });
  }
}
