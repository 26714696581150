'use strict';

export default class List {
  constructor() {
    (function($) {
      $.extend({
        listGroup: {
          lists: {},
          init: $obj => {
            const listGroupConfig = $obj.data('list-group');

            const $toggler = $obj.find('[data-list-group-toggler]');

            if (!$.listGroup.lists[listGroupConfig.parentId]) {
              $.listGroup.lists[listGroupConfig.parentId] = {};
            }

            $.listGroup.lists[listGroupConfig.parentId][listGroupConfig.label] = $toggler.attr('aria-expanded') === 'true';
          },
          isActive: (parentId, label) => {
            return $.listGroup.lists[parentId] ? $.listGroup.lists[parentId][label] : false;
          }
        }
      });
    })($);

    $('body').on('hidden.bs.collapse shown.bs.collapse', '[data-list-group] [data-list-group-menu]', function() {
      $.listGroup.init($(this).closest('[data-list-group]'));
    });
  }
};
